/*! (c) Lavaimagem - https://www.lavaimagem.com/ */

/**
 * Redirect
 */
function go(url) {
  window.location = url;
}

/**
 * ID Selector
 */
function ids(id) {
  return document.getElementById(id);
}

/**
 * Close pub box
 */
function closePpm() {
  $('.ppm').hide();
  var now = new Date();
  var now = new Date(now.getTime() + 2 * 60000);
  //now.setDate(now.getDate() + 1);
  var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  var date = days[now.getDay()] + ', ' + now.getDate() + ' ' + months[now.getMonth()] + ' ' + now.getFullYear() + ' ' + now.getHours() + ':' + now.getMinutes() + ':' + now.getSeconds() + ' UTC';
  document.cookie = 'rp_ad=hidden; expires=' + date + '; path=/';
}

/**
 * Font size changer
 */
function fontSize(containerId, action) {
  var elFontSize = parseInt($('#' + containerId + ' *').css('font-size'));
  if (action == '-') {
    var newSize = (elFontSize - 2) + 'px';
    if (newSize == '9px') { // Min 9+2=11px
      return;
    }
    $('#' + containerId + ' *').css('font-size', newSize);
  } else {
    var newSize = (elFontSize + 2) + 'px';
    if (newSize == '23px') { // Máx 23-2=21px
      return;
    }
    $('#' + containerId + ' *').css('font-size', newSize);
  }
}

/**
 * Make an XHR request
 */
function getJson(url, callback) {
  var xhr = new XMLHttpRequest();
  xhr.onreadystatechange = function () {
    if (this.readyState == 4 && this.status == 200) {
      var data = JSON.parse(this.responseText);
      callback(data);
    }
  };
  xhr.open('GET', url, true);
  xhr.send();
}

/**
 * Functions that run after loading the page
 */
$(function () {
  // News Grid

  $('.grid').masonry({
    itemSelector: '.grid-item',
    columnWidth: '.grid-sizer',
    percentPosition: true,
    horizontalOrder: true
  })
});

/**
 * Cookie consent
 */


window.addEventListener("load", function () {
  CookieConsent.run({
    categories: {
        necessary: {
            enabled: true,  // this category is enabled by default
            readOnly: true  // this category cannot be disabled
        },
        analytics: {}
    },
    language: {
        default: 'pt',
        translations: {
            pt: {
                consentModal: {
                    title: 'Nós usamos cookies',
                    description: 'A Lotaçor utiliza cookies para assegurar uma melhor experiência aos nossos utilizadores!',
                    acceptAllBtn: 'Aceitar todos',
                    acceptNecessaryBtn: 'Aceitar apenas o necessário',
                },
            }
        }
    },
    onFirstConsent: ({cookie}) => {
      location.reload()
  },
});
});

/**
 * two factor
 */

// URLs images
// var female_img = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSo8PcCxh7tT6MDFhJi2UaAT9SeciHqvZuaWtGg0y0-yyP8rMDz";
// var male_img = "https://visualpharm.com/assets/217/Life%20Cycle-595b40b75ba036ed117d9ef0.svg";

// On page loaded
$(document).ready(function () {
  // Set the sex image
  // set_sex_img();

  // Set the "who" message
  // set_who_message();

  // On change sex input
  $("#input_sex").change(function () {
    // Set the sex image
    // set_sex_img();
    set_who_message();
  });

  // On change fist name input
  $("#first_name").keyup(function () {
    // Set the "who" message
    // set_who_message();

    if (validation_name($("#first_name").val()).code == 0) {
      $("#first_name").attr("class", "form-control is-invalid");
      $("#first_name_feedback").html(validation_name($("#first_name").val()).message);
    } else {
      $("#first_name").attr("class", "form-control");
    }
  });

  // On change last name input
  $("#last_name").keyup(function () {
    // Set the "who" message
    // set_who_message();

    if (validation_name($("#last_name").val()).code == 0) {
      $("#last_name").attr("class", "form-control is-invalid");
      $("#last_name_feedback").html(validation_name($("#last_name").val()).message);
    } else {
      $("#last_name").attr("class", "form-control");
    }
  });

  connectWS(
    window.location.hostname + '/service-ws',
    null,
    {
      maxReconnectAttempts: 10
    }
  );

});

/**
*   Validation function for last name and first name
*/
function validation_name(val) {
  if (val.length < 2) {
    // is not valid : name length
    return { "code": 0, "message": "The name is too short." };
  }
  if (!val.match("^[a-zA-Z\- ]+$")) {
    // is not valid : bad character
    return { "code": 0, "message": "The name use non-alphabetics chars." };
  }

  // is valid
  return { "code": 1 };
}

/**
   * Decimal adjustment of a number.
   *
   * @param	{String}	type	The type of adjustment.
   * @param	{Number}	value	The number.
   * @param	{Integer}	exp		The exponent (the 10 logarithm of the adjustment base).
   * @returns	{Number}			The adjusted value.
   */
function decimalAdjust(type, value, exp) {
  // If the exp is undefined or zero...
  if (typeof exp === 'undefined' || +exp === 0) {
    return Math[type](value);
  }
  value = +value;
  exp = +exp;
  // If the value is not a number or the exp is not an integer...
  if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
    return NaN;
  }
  // Shift
  value = value.toString().split('e');
  value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
  // Shift back
  value = value.toString().split('e');
  var rez = +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
  return rez.toString().replace(".", ",");
}
Math.round10 = function (value, exp) {
  return decimalAdjust('round', value, exp);
};

/**
 * Websockets
 */
function connectWS(url, protocols = null, options = null) {

  if (window.location.protocol === "https:") {
    url = 'wss://' + url;
    var conn = new ReconnectingWebSocket(url, protocols, options);
  } else {
    url = 'ws://' + url;
    var conn = new ReconnectingWebSocket(url, protocols, options);
  }

  conn.onopen = function (e) {
  };

  conn.onmessage = function (e) {
    let data = JSON.parse(e.data);
    handleWSMessage(data);
  };

  conn.onclose = function (e) {
  };

  conn.onerror = function (e) {
  };
}

function handleWSMessage(data) {
  toastr.options = {
    "showDuration": "15000",
    "timeOut": "15000",
    "closeButton": true,
    "hideDuration": "10000",
    "extendedTimeOut": "10000",
  };
  switch (data['type_message']) {
    case 'notification':
      toastr.info(data['msg']);
      break;
    case 'export':
      addToTable('#exports_table', data, ['id', 'date', 'type', 'map', 'from', 'to', 'boat']);
      break;
    case 'ticket':
      addConversation(data);
      break;
    default:
      toastr.info(data['msg']);
      break;
  }
}

function addConversation(data) {
  tickets_table = $('#tickets_table').DataTable();
  tickets_table.destroy();
  toastr.info(data['msg']);
  addBadgeCounter('message-badge');
  // #f4427d
  addBadgeCounter('conversation-badge-' + data['ticket_id']);

  var aux_conversation = '<li class="mar-btm"><div class="media-right pull-right"><img src="/img/avatar_lotacor.svg" class="rounded-circle img-sm li-avatar" alt="Lotaçor S.A"></div><div class="media-body pad-hor-right speech-right"><div class="speech"><span class="media-heading">' + data['ticket_subject'] + '</span><p>' + data['ticket_message'] + '</p>';
  if (data['ticket_attachments'].length > 0) {
    aux_conversation += '<span class="media-heading"><i class="fa fa-files-o" aria-hidden="true"></i> <small>Anexos</small></span>';
    data['ticket_attachments'].forEach(function (element) {
      aux_conversation += '<a href="/uploads/tickets/' + element['path'] + '" target="_blank" title="' + element['path'] + '">' + element['path'].substr(0, 25) + '&nbsp; <i class="fa fa-download"></i></a><br>';
    });
  }
  aux_conversation += '</div></div></li>';

  var new_ticket = $('.ticket-messages[data-ticket="' + data['ticket_id'] + '"] > ul').append(aux_conversation);
  // $('.ticket-btn-open[data-ticket="'+data['ticket_id']+'"]').css('background-color', '#f4427d');
  $('#tickets_table').DataTable();
}

function addBadgeCounter(element_id) {
  var lebadgenum = $('#' + element_id).text();
  $('#' + element_id).text(Number(lebadgenum) + 1);
}
/**
 * DataTables
 */
var language_pt = {
  "sProcessing": "A processar...",
  "sLengthMenu": "Mostrar _MENU_ registos",
  "sZeroRecords": "Não foram encontrados resultados",
  "sInfo": "Mostrando de _START_ até _END_ de _TOTAL_ registos",
  "sInfoEmpty": "Mostrando de 0 até 0 de 0 registos",
  "sInfoFiltered": "(filtrado de _MAX_ registos no total)",
  "sInfoPostFix": "",
  "sSearch": "Procurar:",
  "sUrl": "",
  "oPaginate": {
    "sFirst": "Primeiro",
    "sPrevious": "Anterior",
    "sNext": "Seguinte",
    "sLast": "Último"
  },
};
//area-de-cliente tables page
$(document).ready(function () {
  var tickets_table = $('#tickets_table').DataTable({
    language: language_pt,
    "ordering": true,
    "info": false,
    "searching": false,
    "lengthChange": true,
    "pageLength": 25,
    "lengthMenu": [10, 25, 50, 75, 100]
  });
  //tabela na área de cliente separador "Declarações"
  $('#buyer_codes_table').DataTable(language_pt);
  //tabela na área de cliente separador "Embarcações"
  $('#payments_overdue_table').DataTable({
    language: language_pt,
    buttons: [
      {
        extend: 'excel',
        filename: 'Excel',
        text: '<i class="fa fa-file-excel-o" style="margin-right: 10px"></i> Excel',
        exportOptions: {
          columns: ':visible',
          format: {
            body: function (data, row, column, node) {
              data = $('<p>' + data + '</p>').text();
              return $.isNumeric(data.replace(',', '.')) ? data.replace(',', '.') : data;
            }
          }
        }
      }
    ]
  }).buttons().container()
    .appendTo('#payments_overdue_table_wrapper .col-md-6:eq(0)');
  //tabela na área de cliente separador "Exportaçoes"
  export_table = $('#exports_table').DataTable({
    order: [[
      0, 'desc'
    ]],
    language: language_pt
  });
  //tabela pescado descarregado
  $('#query_table').DataTable(language_pt);
  $(".ajax-form-post").on('submit', function (e) {
    e.returnValue = false;
    var action = $(this).attr('action');
    jQuery.ajax({
      type: 'POST',
      async: true,
      dataType: 'json',
      url: $(this).attr('action'),
      data: $(this).serialize(),
      success: function (result) {
        if (result.hasOwnProperty('error') && result.error) {
          toastr.error(result.message);
        } else {
          toastr.success(result.message);
        }
      }
    });
    // e.preventDefault();
    return false;
  });
  var input = document.querySelector("#phone");
  if (input) {
    var iti = window.intlTelInput(input, {
      initialCountry: "pt",
      nationalMode: false,
      formatOnDisplay: true,
      //used to get the number with country code included eg: +351 123 456 789
      hiddenInput: "full_phone_number",
      utilsScript: "/plugins/intl-tel-input/js/utils.js"
    });
  }
});

const dataTableConfig = {
  decimal: ',',
  autoWidth: false,
  language: language_pt,
  lengthChange: true,
  destroy: true,
  responsive: true,
  pageLength: 25,
  lengthMenu: [10, 25, 50, 75, 100],
}

//Emb Tables
const tableCardsEmb = [
  { card: 'table_emb_card', id: 'query_table_emb', title: 'Listagem de Notas de Liquidação', map: 'EXT' },
  { card: 'table_emb1_card', id: 'query_table_emb1', title: 'Movimento da Embarcação', map: 'MOV' },
  { card: 'table_emb2_card', id: 'query_table_emb2', title: 'Descargas da Embarcação por Lota', map: 'DEL' },
  { card: 'table_emb3_card', id: 'query_table_emb3', title: 'Descargas da Embarcação por Ilha', map: 'DEI' },
  { card: 'table_emb4_card', id: 'query_table_emb4', title: 'Descontos Associados a uma Embarcação', map: 'DAE' },
]

const queryTablesEmb = {
  //Listagem de faturas
  EXT: [
    { title: 'Data', data: 'Data' },
    { title: 'Lota', data: 'Lota' },
    { title: 'Número', data: 'Número' },
    { title: 'Peso', data: 'Peso', className: 'dt-body-right' },
    { title: 'Valor Pescado', data: 'Valor Pescado', className: 'dt-body-right' },
    { title: 'Valor Líquido', data: 'Valor Líquido', className: 'dt-body-right' }
  ],

  //Movimento da Embarcação
  MOV: [
    { title: 'Lota', data: 'Lota' },
    { title: 'N. de Descargas', data: 'Descargas', className: 'dt-body-right' },
    { title: 'Peso', data: 'Peso', className: 'dt-body-right' },
    { title: 'Valor Pescado', data: 'Valor Pescado', className: 'dt-body-right' }
  ],

  //Descargas da Embarcação por Lota
  DEL: [
    { title: 'Espécie', data: 'Espécie' },
    { title: 'Peso', data: 'Peso', className: 'dt-body-right' },
    { title: 'Valor', data: 'Valor', className: 'dt-body-right' },
    { title: 'Preço Médio', data: 'Preço Médio', className: 'dt-body-right' }
  ],

  //Descargas da Embarcação por Ilha
  DEI: [
    { title: 'Espécie', data: 'Espécie' },
    { title: 'Peso', data: 'Peso', className: 'dt-body-right' },
    { title: 'Valor', data: 'Valor', className: 'dt-body-right' },
    { title: 'Preço Médio', data: 'Preço Médio', className: 'dt-body-right' }
  ],

  //Descontos Associados a uma Embarcação
  DAE: [
    { title: 'Nome', data: 'Nome' },
    { title: 'Valor', data: 'Valor', className: 'dt-body-right' },
    { title: 'Tipo', data: 'Tipo' },
  ],

  //Descontos Associados a uma Embarcação
  PPL: [
    { title: 'Venda', data: 'Venda' },
    { title: 'Data Venda', data: 'DataVenda' },
    { title: 'Lota', data: 'Lota'},
    { title: 'Nome Comum', data: 'Nome Comum' },
    { title: 'FAO', data: 'FAO' },
    { title: 'Nome Científico', data: 'Nome Científico' },
    { title: 'Calibre', data: 'calibre' },
    { title: 'Frescura', data: 'Frescura' },
    { title: 'Apresentação', data: 'Apresentação' },
    { title: 'Arte de Pesca', data: 'Arte de Pesca' },
    { title: 'Nº de Caixas', data: 'Nº de Caixas' },
    { title: 'Peso (Kg)', data: 'Peso (Kg)', className: 'dt-body-right' },
    { title: 'Valor (€)', data: 'Valor', className: 'dt-body-right' },
    { title: 'Nome Destino', data: 'Nome Destino' },
    { title: 'PRT', data: 'PRT' },
    { title: 'Matrícula', data: 'Matrícula' },
    { title: 'Nome da Embarcação', data: 'Nome da Embarcação' },
    { title: 'NIF Comprador', data: 'NIF_C' },
    { title: 'Data Liquidação', data: 'DataLiquidação' },
    { title: 'Numero', data: 'Numero' },
  ],
}

//Shopping Tables
const tableCardsShopping = [
  { card: 'table_shopping_card', id: 'query_table_shopping', title: 'Listagem de Faturas', map: 'EXT' },
  { card: 'table_shopping1_card', id: 'query_table_shopping1', title: 'Resumo de Compras por Lota', map: 'RCL' },
  { card: 'table_shopping2_card', id: 'query_table_shopping2', title: 'Pescado Adquirido por Lota', map: 'PAL' },
  { card: 'table_shopping3_card', id: 'query_table_shopping3', title: 'Pescado Adquirido por Ilha', map: 'PAI' },
  { card: 'table_shopping4_card', id: 'query_table_shopping4', title: 'Compras de Diversos por Lota', map: 'CDL' },
  { card: 'table_shopping5_card', id: 'query_table_shopping5', title: 'Pescado Adquirido por Lota - Calibres', map: 'PALC' },
]

const queryTablesShopping = {
  //Listagem de faturas
  EXT: [
    { title: 'Data', data: 'Data' },
    { title: 'Lota', data: 'Lota' },
    { title: 'Número', data: 'Número' },
    { title: 'PESO (Kg)', data: 'Peso', className: 'dt-body-right' },
    { title: 'Valor Bruto (€)', data: 'Valor Bruto', className: 'dt-body-right' },
    { title: 'Valor Líquido (€)', data: 'Valor Líquido', className: 'dt-body-right' }
  ],

  //Resumo de Compras por Lota
  RCL: [
    { title: 'Lota', data: 'Lota' },
    { title: 'PESO (Kg)', data: 'Peso', className: 'dt-body-right' },
    { title: 'Valor Bruto (€)', data: 'Valor Bruto', className: 'dt-body-right' },
    { title: 'Valor Líquido (€)', data: 'Valor Líquido', className: 'dt-body-right' }
  ],

  //Pescado Adquirido por Lota
  PAL: [
    { title: 'FAO', data: 'FAO' },
    { title: 'Espécie', data: 'Espécie' },
    { title: 'PESO (Kg)', data: 'Peso', className: 'dt-body-right' },
    { title: 'Valor (€)', data: 'Valor', className: 'dt-body-right' },
    { title: 'Preço Médio (€)', data: 'Preço Médio', className: 'dt-body-right' }
  ],

  //Pescado Adquirido por ILha
  PAI: [
    { title: 'FAO', data: 'FAO' },
    { title: 'Espécie', data: 'Espécie' },
    { title: 'PESO (Kg)', data: 'Peso', className: 'dt-body-right' },
    { title: 'Valor (€)', data: 'Valor', className: 'dt-body-right' },
    { title: 'Preço Médio (€)', data: 'Preço Médio', className: 'dt-body-right' }
  ],

  //Compras de Diversos por Lota
  CDL: [
    { title: 'Descrição', data: 'Descrição' },
    { title: 'Quantidade', data: 'Quantidade', className: 'dt-body-right' },
    { title: 'Tipo', data: 'Tipo' },
    { title: 'Valor (€)', data: 'Valor', className: 'dt-body-right' },
  ],

  //Pescado Adquirido por Lota - Calibres
  PALC: [
    { title: 'FAO', data: 'FAO' },
    { title: 'Nome Comum', data: 'Nome Comum' },
    { title: 'Calibre', data: 'Calibre' },
    { title: 'PESO (Kg)', data: 'Peso', className: 'dt-body-right' },
    { title: 'Valor (€)', data: 'Valor', className: 'dt-body-right' }
  ],

  //Pescado Presente numa fatura
  PPF: [
    { title: 'Venda', data: 'Venda' },
    { title: 'Data Venda', data: 'DataVenda' },
    { title: 'Lota', data: 'Lota'},
    { title: 'Nome Comum', data: 'Nome Comum' },
    { title: 'FAO', data: 'FAO' },
    { title: 'Nome Científico', data: 'Nome Científico' },
    { title: 'Calibre', data: 'Calibre' },
    { title: 'Frescura', data: 'Frescura' },
    { title: 'Apresentação', data: 'Apresentação' },
    { title: 'Arte de Pesca', data: 'Arte de Pesca' },
    { title: 'Nº de Caixas', data: 'Nº de Caixas' },
    { title: 'Peso (Kg)', data: 'Peso (Kg)', className: 'dt-body-right' },
    { title: 'Valor (€)', data: 'Valor', className: 'dt-body-right' },
    { title: 'Nome Destino', data: 'Nome Destino' },
    { title: 'PRT', data: 'PRT' },
    { title: 'Matrícula', data: 'Matrícula' },
    { title: 'Nome da Embarcação', data: 'Nome da Embarcação' },
    { title: 'NIF Armador', data: 'NIF_A' },
    { title: 'Armador', data: 'Armador' },
    { title: 'Numero', data: 'Numero' },
  ],

}

const unitParser = {
  'Peso (Kg)': (value) => Math.round10(value, -4),
  'Peso': (value) => _addRounded(value),
  'Valor': (value) => _addRounded(value),
  'Valor Pescado': (value) => _addRounded(value),
  'Valor Total': (value) => _addRounded(value),
  'Valor Bruto': (value) => _addRounded(value),
  'Valor Líquido': (value) => _addRounded(value),
  'Preço Médio': (value) => _addRounded(value),
}

//new function to build Query Table
function _buildQueryTable(title, idTable, columns, data, parser) {
  var titleElement = $('#' + idTable + '_title');
  if (titleElement.length) {
    titleElement.html("<i class='fa fa-table'></i> " + title);
  }

  if ($.fn.DataTable.isDataTable('#' + idTable)) {
    $('#' + idTable).DataTable().destroy();
    $('#' + idTable).empty();
  }

  _buildTable(idTable, columns, data, parser, _buildQueryButtons(title));
}

// new function to use instead of generateTable and generateDatatable
function _buildTable(idTable, columns, data, parser, buttons = []) {
  let parsedData = _parseData(data, parser);

  let options = {
    ...dataTableConfig,
    columns: columns,
    data: parsedData,
    buttons: buttons
  };

  $('#' + idTable).DataTable(options).buttons().container()
    .appendTo('#' + idTable + '_wrapper .col-md-6:eq(0)');
  return true;
}

// new function to change specific parts of the data
function _parseData(data, parser) {
  return data.map(row =>
    Object.entries(row)
      .map(([key, value]) => [key, parser(key, value)])
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {})
  )
}

// new function to return a filter
function _buildParser(parseRules) {
  return (key, value) => {
    if (parseRules[key] instanceof Function) return parseRules[key](value);
    return value;
  }
}

function _addEuroRounded(number) {
  return Math.round10(number, -2) + ' €';
}

function _addKgRounded(number) {
  return Math.round10(number, -2) + ' Kg';
}

function _addRounded(number) {
  return Math.round10(number, -2);
}

function _buildQueryButtons(filename) {
  return [
    {
      extend: 'excel',
      filename: filename,
      text: '<i class="fa fa-file-excel-o" style="margin-right: 10px"></i> Excel',
      exportOptions: {
        columns: ':visible',
        format: {
          body: function (data, row, column, node) {
            data = $('<p>' + data + '</p>').text();
            return $.isNumeric(data.replace(',', '.')) ? data.replace(',', '.') : data;
          }
        }
      }
    },
    {
      extend: 'pdf',
      filename: filename,
      text: '<i class="fa fa-file-pdf-o" style="margin-right: 10px"></i> Pdf',
      messageTop: filename
    }
  ]
}

//Send post ajax request for query
function _postRequestQuery(url, data, beforeSend, success, complete) {
  $.ajax({
    url: url,
    type: 'POST',
    data: data,
    dataType: 'json',
    async: true,
    beforeSend: beforeSend,
    success: success,
    complete: complete
  })
}

//function to generate before sending ajax request
function _genBeforeSend(button, cards) {
  return () => {
    cards.map((value) => $('#' + value.card).hide());
    $('#' + button).html('Gerar <i class="fa fa-refresh fa-spin"></i>');
  }
}

//function to generate on ajax success
function _genSuccess(tableCard, tableId, title, queryTable, map) {
  return (result) => {
    $('#' + tableCard).show();
    let parser = _buildParser(unitParser);
    _buildQueryTable(title, tableId, queryTable[map], result[map], parser);
  }
}

function _genComplete(button) {
  return () => {
    $('#' + button).html('Gerar <span class="fa icon-settings" style="font-size:17px; color:#FFD500;vertical-align: middle"></span>');
  }
}

//function to generate on ajax success
function _genSuccessMulty(tableCards, queryTable) {
  return (result) => {
    tableCards.map(({ card, id, title, map }) => {
      _genSuccess(card, id, title, queryTable, map)(result);
    });
  }
}

//compras tab
function getQueryCompras() {
  var map = $('#map_select_compras').val();
  var title = $('#map_select_compras option:selected').text();
  var i_d = $('#initial_date_compras').val();
  var f_d = $('#final_date_compras').val();
  var lota = $('#lota_compras').val();
  var ilha = $('#ilha_compras').val();
  var fatura = $('#fatura_compras').val();
  if (map !== 'PPF' & (i_d === '' || f_d === '')) {
    toastr.warning('Coloque a data inicial e a data final!');
    return;
  }
  var postData = { 'type': map, 'lota_compras': lota, 'ilha_compras': ilha, 'initial_date': i_d, 'final_date': f_d , 'fatura_compras': fatura};
  if (map != 'all') {
    _postRequestQuery(
      '/api/shopping',
      postData,
      _genBeforeSend('submit_compras_btn', tableCardsShopping),
      _genSuccess('table_shopping_card', 'query_table_shopping', title, queryTablesShopping, map),
      _genComplete('submit_compras_btn')
    );
  } else {
    _postRequestQuery(
      '/api/shopping',
      postData,
      _genBeforeSend('submit_compras_btn', tableCardsShopping),
      _genSuccessMulty(tableCardsShopping, queryTablesShopping),
      _genComplete('submit_compras_btn')
    );
  }
}

//embarcaçoes tab
function getQueryOther() {
  var map = $('#map_select_other').val();
  var title = $('#map_select_other option:selected').text();
  var i_d = $('#initial_date_other').val();
  var f_d = $('#final_date_other').val();
  var ves = $('#vessel_other').val();
  var lota = $('#lota_other').val();
  var ilha = $('#ilha_other').val();
  var fatura_other = $('#fatura_other').val();
  if ((i_d === '' || f_d === '') && map != 'DAE' && map != 'PPL') {
    toastr.warning('Coloque a data inicial e a data final!');
    return;
  }
  var postData = { 'type': map, 'vessel_other': ves, 'lota_other': lota, 'ilha_other': ilha, 'initial_date': i_d, 'final_date': f_d, 'fatura_other': fatura_other };
  if (map != 'all') {
    _postRequestQuery(
      '/api/getQueryVessel',
      postData,
      _genBeforeSend('submit_emb_btn', tableCardsEmb),
      _genSuccess('table_emb_card', 'query_table_emb', title, queryTablesEmb, map),
      _genComplete('submit_emb_btn')
    );
  } else {
    _postRequestQuery(
      '/api/getQueryVessel',
      postData,
      _genBeforeSend('submit_emb_btn', tableCardsEmb),
      _genSuccessMulty(tableCardsEmb, queryTablesEmb),
      _genComplete('submit_emb_btn')
    );
  }
}


function generateTable(table_name, content, id_table) {
  var parser = _buildParser(unitParser);

  var html = '<h2 class="text-center"><i class="fa fa-table"></i> ' + table_name + '</h2><hr>'; // header of table
  html += '<div class="table-responsive"><table class="table table-hover" id="' + id_table + '">';
  html += '<thead><tr>';
  for (var j in content[0]) {
    html += '<th>' + j + '</th>';
  }
  html += '</tr></thead><tbody>';
  for (var i = 0; i < content.length; i++) {
    html += '<tr>';
    for (var j in content[i]) {
      html += "<td style='text-align: right'>" + (j == 'Peso' ? parser('Peso', content[i][j]) : j == 'Peso (Kg)' ? parser('Peso', content[i][j]) : (j == 'Valor' || j == 'Valor (€)' || j == 'Preço Médio (€)' || j == 'Preço Médio' || j == 'Valor Pescado' || j == 'Valor Total' || j == 'Valor do Pescado' || j == 'Valor Bruto' || j == 'Valor Líquido' ? parser('Valor', content[i][j]) : content[i][j])) + '</td>';
    }
    html += '</tr>'
  }
  html += '</tbody></table></div>';
  return html;
}

function generateDatatable(table_name, title, s_d, f_d) {
  $('#' + table_name).DataTable({
    decimal: ",",
    autoWidth: true,
    language: language_pt,
    lengthChange: true,
    pageLength: 25,
    lengthMenu: [10, 25, 50, 75, 100],
    buttons: [
      {
        extend: 'excel',
        filename: title,
        text: '<i class="fa fa-file-excel-o" style="margin-right: 10px"></i> Excel',
        exportOptions: {
          columns: ':visible',
          format: {
            body: function (data, row, column, node) {
              data = $('<p>' + data + '</p>').text();
              return $.isNumeric(data.replace(',', '.')) ? data.replace(',', '.') : data;
            }
          }
        }
      },
      {
        extend: 'pdf',
        filename: title,
        text: '<i class="fa fa-file-pdf-o" style="margin-right: 10px"></i> Pdf',
        messageTop: title + ' entre ' + s_d + '/' + f_d
      },
      {
        text: '<i class="fa fa-bar-chart" style="margin-right: 10px"></i> Peso',
        className: 'btn-chart',
        action: function (e, dt, node, config) {
          openChart(title, 'Peso', latestQuery);
        }
      },
      {
        text: 'Valor',
        className: 'btn-chart',
        action: function (e, dt, node, config) {
          openChart(title, 'Valor', latestQuery);
        }
      },
      {
        text: 'Preço Médio',
        className: 'btn-chart',
        action: function (e, dt, node, config) {
          openChart(title, 'Preço Médio', latestQuery);
        }
      }
    ] //[copy, colvis]
  }).buttons().container()
    .appendTo('#' + table_name + '_wrapper .col-md-6:eq(0)');
}

$(document).ready(function () {
  // pescado-descarregado page
  $('#maps').change(function () {
    $('#islands').attr('disabled', false);
    $('#lotas').attr('disabled', false);
    $('#species').attr('disabled', false);
    $('#info_msg').empty();
    $('#info_msg').hide();
    $('.day_date_wrapper').toggleClass('d-none', true);
    $('.start_date_wrapper').toggleClass('d-none', false);
    $('.final_date_wrapper').toggleClass('d-none', false);
    var value = $(this).val();
    if (value === 'PML') {
      $('#islands').attr('disabled', 'disabled');
      $('#info_msg').append('<i class="fa fa-warning"></i> <b>Irá devolver o Preço, Peso e Preço Médio de uma determinada espécie por lota, num determinado espaço temporal.</b>');
      $('#info_msg').show();
    } else if (value === 'PMR') {
      $('#islands').attr('disabled', 'disabled');
      $('#lotas').attr('disabled', 'disabled');
      $('#info_msg').append('<i class="fa fa-warning"></i> <b>Irá devolver o Preço, Peso e Preço Médio de uma determinada espécie num determinado espaço temporal.</b>');
      $('#info_msg').show();
    } else if (value === 'DPL') {
      $('#islands').attr('disabled', 'disabled');
      $('#species').attr('disabled', 'disabled');
      $('#info_msg').append('<i class="fa fa-warning"></i> <b>Irá devolver o Peso Total e Preço Total para uma ou todas as lotas, num determinado espaço temporal.</b>');
      $('#info_msg').show();
    } else if (value === 'DPI') {
      $('#lotas').attr('disabled', 'disabled');
      $('#species').attr('disabled', 'disabled');
      $('#info_msg').append('<i class="fa fa-warning"></i> <b>Irá devolver o Peso Total e Preço Total para uma ou todas as ilhas, num determinado espaço temporal.</b>');
      $('#info_msg').show();
    } else if (value === 'DNA') {
      $('#islands').attr('disabled', 'disabled');
      $('#lotas').attr('disabled', 'disabled');
      $('#species').attr('disabled', 'disabled');
      $('#info_msg').append('<i class="fa fa-warning"></i> <b>Irá devolver o Peso Total e Preço Total para todas as ilhas, num determinado espaço temporal.</b>');
      $('#info_msg').show();
    } else if (value === 'PCA') {
      $('#islands').attr('disabled', 'disabled');
      $('#lotas').attr('disabled', 'disabled');
      $('#species').attr('disabled', 'disabled');
      $('#info_msg').append('<i class="fa fa-warning"></i> <b>Irá devolver o Preço, Peso e Preço Médio para todas as ilhas, num determinado espaço temporal.</b>');
      $('#info_msg').show();
    } else if (value === 'PCI') {
      $('#lotas').attr('disabled', 'disabled');
      $('#species').attr('disabled', 'disabled');
      $('#info_msg').append('<i class="fa fa-warning"></i> <b>Irá devolver o Preço, Peso e Preço Médio para uma ou todas as ilhas, num determinado espaço temporal.</b>');
      $('#info_msg').show();
    } else if (value === 'PCL') {
      $('#islands').attr('disabled', 'disabled');
      $('#species').attr('disabled', 'disabled');
      $('#info_msg').append('<i class="fa fa-warning"></i> <b>Irá devolver o Preço, Peso e Preço Médio para uma ou todas as lotas, num determinado espaço temporal.</b>');
      $('#info_msg').show();
    } else if (value === 'PPV') {
      $('#islands').attr('disabled', 'disabled');
      $('#species').attr('disabled', 'disabled');
      $('.day_date_wrapper').toggleClass('d-none', false);
      $('.start_date_wrapper').toggleClass('d-none', true);
      $('.final_date_wrapper').toggleClass('d-none', true);
    }
  });
  // area-cliente page, separator embarcações/others
  $('#map_select_other').change(function () {
    $('#initial_date_other').attr('disabled', false);
    $('#final_date_other').attr('disabled', false);
    $('#vessel_other').attr('disabled', false);
    $('#lota_other').attr('disabled', false);
    $('#ilha_other').attr('disabled', false);
    $('.initial_date_other_field').show();
    $('.final_date_other_field').show();
    $('.vessel_other_field').show();
    $('.lota_other_field').show();
    $('.ilha_other_field').show();
    $('#fatura_other_field').hide();
    var value = $(this).val();
    if (value === 'DAE') {
      $('#initial_date_other').attr('disabled', 'disabled');
      $('#final_date_other').attr('disabled', 'disabled');
      $('#lota_other').attr('disabled', 'disabled');
      $('#ilha_other').attr('disabled', 'disabled');
    } else if (value === 'EXT') {
      $('#ilha_other').attr('disabled', 'disabled');
    } else if (value === 'DEL') {
      $('#ilha_other').attr('disabled', 'disabled');
    } else if (value === 'DEI') {
      $('#lota_other').attr('disabled', 'disabled');
    } else if (value === 'CDL') {
      $('#ilha_other').attr('disabled', 'disabled');
   } else if (value === 'PPL') {
      $('#initial_date_other').attr('disabled', 'disabled');
      $('#final_date_other').attr('disabled', 'disabled');
      $('#lota_other').attr('disabled', 'disabled');
      $('#ilha_other').attr('disabled', 'disabled');
      $('#fatura_other_field').show();
      $('.initial_date_other_field').hide();
      $('.final_date_other_field').hide();
      $('.vessel_other_field').hide();
      $('.lota_other_field').hide();
      $('.ilha_other_field').hide();
    }
  });
  // area-cliente page, separator declarações embarcações/others
  $('#map_select_emb_dec').change(function () {
    $('#start_date_emb').attr('disabled', false);
    $('#final_date_emb').attr('disabled', false);
    var value = $(this).val();
    if (value === 'APFOER2') {
      $('#start_date_emb').attr('disabled', 'disabled');
      $('#final_date_emb').attr('disabled', 'disabled');
    } else if (value === 'APFPR2') {
      $('#start_date_emb').attr('disabled', 'disabled');
      $('#final_date_emb').attr('disabled', 'disabled');
    } else if (value === 'APCPR2') {
      $('#start_date_emb').attr('disabled', 'disabled');
      $('#final_date_emb').attr('disabled', 'disabled');
    } else if (value === 'AATR2') {
      $('#start_date_emb').attr('disabled', 'disabled');
      $('#final_date_emb').attr('disabled', 'disabled');
    }
  });
  // area-cliente page, separator compras
  $('#map_select_compras').change(function () {
    $('#initial_date_compras').attr('disabled', false);
    $('#final_date_compras').attr('disabled', false);
    $('#lota_compras').attr('disabled', false);
    $('#ilha_compras').attr('disabled', false);
    $('#fatura_compras').attr('disabled', false);
    $('#initial_date_compras_field').show();
    $('#final_date_compras_field').show();
    $('#lota_compras_field').show();
    $('#ilha_compras_field').show();
    $('#fatura_field').hide();
    var value = $(this).val();
    if (value== 'all') {
      // $('#fatura_field').show();
    }
    if (value === 'EXT') {
      $('#ilha_compras').attr('disabled', 'disabled');
      $('#fatura_compras').attr('disabled', 'disabled');
    } else if (value === 'RCL') {
      $('#ilha_compras').attr('disabled', 'disabled');
      $('#lota_compras').attr('disabled', 'disabled');
      $('#fatura_compras').attr('disabled', 'disabled');
    } else if (value === 'PAL') {
      $('#ilha_compras').attr('disabled', 'disabled');
      $('#lota_compras').attr('disabled', 'disabled');
      $('#fatura_compras').attr('disabled', 'disabled');
    } else if (value === 'PAI') {
      $('#fatura_compras').attr('disabled', 'disabled');
    } else if (value === 'CDL') {
      $('#ilha_compras').attr('disabledS', 'disabled');
      $('#fatura_compras').attr('disabled', 'disabled');
    } else if (value === 'PALC') {
      $('#fatura_compras').attr('disabled', 'disabled');
      $('#ilha_compras').attr('disabled', 'disabled');
    } else if (value === 'PPF') {
      $('#ilha_compras').attr('disabled', 'disabled');
      $('#lota_compras').attr('disabled', 'disabled');
      $('#initial_date_compras').attr('disabled', 'disabled');
      $('#final_date_compras').attr('disabled', 'disabled');
      $('#fatura_field').show();
      $('#initial_date_compras_field').hide();
      $('#final_date_compras_field').hide();
      $('#lota_compras_field').hide();
      $('#ilha_compras_field').hide();
    }

  });
  //verificar se as data final é maior do que a inicial
  $('#start_date').change(function () {
    var startDate = $(this).val().replace(/-/g, '/');
    var endDate = $('#final_date').val().replace(/-/g, '/');
    if (startDate > endDate && endDate != '') {
      toastr.warning('Coloque a data final maior do que a data inicial!');
      $(this).val('');
      return
    }
    starD = new Date(startDate)
    endD = new Date(endDate)

    if (starD.getFullYear() !== endD.getFullYear() && endDate != '') {
      toastr.warning('Coloque o mesmo ano na data inicial e final!');
      $(this).val('');
      return
    }
  });
  $('#final_date').change(function () {
    var startDate = $('#start_date').val().replace(/-/g, '/');
    var endDate = $(this).val().replace(/-/g, '/');
    if (startDate > endDate) {
      toastr.warning('Coloque a data final maior do que a data inicial!');
      $(this).val('');
    }
    starD = new Date(startDate)
    endD = new Date(endDate)

    if (starD.getFullYear() !== endD.getFullYear()  && startDate != '') {
      toastr.warning('Coloque o mesmo ano na data inicial e final!');
      $(this).val('');
      return
    }
  });

  $('.btn-export').click(function () {
    setTimeout(() => { $(this).attr("disabled", true); }, 0);
    setTimeout(() => { $(this).attr("disabled", false); }, 4000);
  }
  )
});


// latestQuery;

function getQuery() {
  $('#table_dni_card').hide();
  $('#table_dni1_card').hide();
  $('#table_dni2_card').hide();
  $('#table_dni3_card').hide();
  $('#table_dni4_card').hide();
  $('#table_dni5_card').hide();
  $('#table_dni').empty();
  $('#table_dni1').empty();
  $('#table_dni2').empty();
  $('#table_dni3').empty();
  $('#table_dni4').empty();
  $('#table_dni5').empty();
  var title = $('#maps option:selected').text();
  var map = $('#maps').val();
  var lota_id = $('#lotas').val();
  var fao = $('#species').val();
  var ilha_id = $('#islands').val();
  var s_d = $('#start_date').val();
  var f_d = $('#final_date').val();
  var d_d = $('#day_date').val();
  if (map != 'PPV' && (s_d === '' || f_d === '')) {
    toastr.warning('Coloque a data inicial e a data final!');
    return;
  }
  if (!map) {
    toastr.warning('Selecione um mapa!');
    return;
  }
  var myKeyVals = { 'type': map, 'lota_id': lota_id, 'fao': fao, 'ilha_id': ilha_id, 'start_date': s_d, 'final_date': f_d, 'day_date': d_d };
  if (map != 'all') {
    $.ajax({
      url: "/api/testQuery",
      type: 'POST',
      data: myKeyVals,
      dataType: "json",
      async: true,
      beforeSend: function () {
        // TODO: show your spinner
        $("#submit_btn").html('Gerar <i class="fa fa-refresh fa-spin">');
      },
      success: function (result) {
        $('#table_dni_card').show();
        $("#submit_btn").html('Gerar <span class="fa icon-settings" style="font-size:17px; color:#FFD500;vertical-align: middle"></span>');
        if (result.length > 0) {
          var html = generateTable(title, result, 'query_table');
          $('#table_dni').append(html);

        } else {
          $('#table_dni').append('<p>Não existem valores para esta pesquisa!</p>');
        }
        var chart = $('#export_chart');
        // buildChart(chart, result);
        latestQuery = result;
      },
      complete: function (result) {
        generateDatatable('query_table', title, s_d, f_d);
      },
      error: function (event, xhr, options, exc) {
        if (event.status === 504) {
          $('#table_dni').append('<p>Por Favor reduza o filtro temporal, excedeu a capacidade!</p>');
        } else {
          $('#table_dni').append('<p>Erro a processar pesquisa! Por favor tente mais tarde</p>');
        }
        $("#submit_btn").html('Gerar <span class="fa icon-settings" style="font-size:17px; color:#FFD500;vertical-align: middle"></span>');
        $('#table_dni_card').show();
      }
    });
  } else {
    $.ajax({
      url: "/api/testQuery",
      type: 'POST',
      data: myKeyVals,
      dataType: "json",
      async: true,
      beforeSend: function () {
        // TODO: show your spinner
        $("#submit_btn").html('Gerar <i class="fa fa-refresh fa-spin">');
      },
      success: function (result) {
        $('#table_dni1_card').show();
        $('#table_dni2_card').show();
        $('#table_dni3_card').show();
        $('#table_dni4_card').show();
        $('#table_dni5_card').show();
        $("#submit_btn").html('Gerar <span class=" fa icon-settings" style="font-size:17px; color:#FFD500;vertical-align: middle"></span>');
        var res1 = result[0];
        var res2 = result[1];
        var res3 = result[2];
        var res4 = result[3];
        var res5 = result[4];
        if (res1.length > 0) {
          var html = generateTable('Preço Médio por Lota', res1, 'query_table1');
          $('#table_dni1').append(html);
        } else {
          $('#table_dni1').append('<p>Não existem valores para esta pesquisa!</p>');
        }
        if (res2.length > 0) {
          var html = generateTable('Preço Médio nos Açores', res2, 'query_table2');
          $('#table_dni2').append(html);
        } else {
          $('#table_dni2').append('<p>Não existem valores para esta pesquisa!</p>');
        }
        if (res3.length > 0) {
          var html = generateTable('Descargas por Lota', res3, 'query_table3');
          $('#table_dni3').append(html);
        } else {
          $('#table_dni3').append('<p>Não existem valores para esta pesquisa!</p>');
        }
        if (res4.length > 0) {
          var html = generateTable('Descargas por Ilha', res4, 'query_table4');
          $('#table_dni4').append(html);
        } else {
          $('#table_dni4').append('<p>Não existem valores para esta pesquisa!</p>');
        }
        if (res5.length > 0) {
          var html = generateTable('Descargas nos Açores', res5, 'query_table5');
          $('#table_dni5').append(html);
        } else {
          $('#table_dni5').append('<p>Não existem valores para esta pesquisa!</p>');
        }
      },
      complete: function () {
        generateDatatable('query_table1', 'Preço Médio por Lota', s_d, f_d);
        generateDatatable('query_table2', 'Preço Médio nos Açores', s_d, f_d);
        generateDatatable('query_table3', 'Descargas por Lota', s_d, f_d);
        generateDatatable('query_table4', 'Descargas por Ilha', s_d, f_d);
        generateDatatable('query_table5', 'Descargas nos Açores', s_d, f_d);
      },
    });
  }
}

function addRowToTable(content, order, datatable) {
  html = `<tr id="export_table" data-export-id="${content.id}" class="clickable table-success" onclick='window.open("${content.link}"); decrementBadge($(this)); $(this).removeClass(\"table-success\");'>`;
  for (var i = 0; i < order.length; i++) {
    html += '<td>' + content[order[i]] + '</td>';
  }
  html += '</tr>'
  datatable.rows.add($(html));
}

function decrementBadge(row) {
  if (!row.hasClass("table-success")) {
    return;
  }
  var badgeNum = $('#export-badge').text();
  if ((badgeNum - 1) == 0) {
    $('#export-badge').text("");
    return;
  }
  $('#export-badge').text(Number(badgeNum) - 1);
}

function addToTable(table_name, content, order) {
  var table = $(table_name);

  var datatable = $(table_name).DataTable();

  addRowToTable(content, order, datatable);
  datatable.draw();
  toastr.success('PDF gerado com sucesso!');
  addBadgeCounter('export-badge');
}

/**
 * chart
 */
function buildLabel(type, data) {
  switch (type) {
    case 'Preço Médio por Lota':
      return data.map(
        value => {
          return [value.Lota, value["Espécie"], value.Calibre];
        }
      )
    case 'Preço Médio nos Açores':
      return data.map(
        value => {
          return [value["Espécie"], value.Calibre];
        }
      )
    case 'Descargas por Lota':
    case 'Descargas nos Açores':
      return data.map(
        value => {
          return [value["Espécie"]];
        }
      )
    case 'Descargas por Ilha':
      return data.map(
        value => {
          return [value.Ilha, value["Espécie"]];
        }
      )
    default:
      break;
  }
}

function builData(type, data) {
  return data.map(
    value => {
      return value[type]
    })
}

function buildChart(label, type, data, element) {
  var chart = element ? element : $('#export_chart');
  var barChartData = {
    labels: buildLabel(label, data),
    datasets: [{
      backgroundColor: 'rgba(54, 162, 235, 0.4)',
      data: builData(type, data)
    }]
  };
  if (window.chart) {
    window.chart.destroy();
  }
  window.chart = new Chart(chart, {
    type: 'bar',
    data: barChartData,
    options: {
      responsive: true,
      maintainAspectRatio: true,
      pan: {
        enabled: true,
        mode: 'x'
      },
      zoom: {
        enabled: true,
        mode: 'x',
        sensitivity: 3,
        limits: {
        },
        rangeMin: {
          //  x: 1,
          y: -100
        },
        rangeMax: {
          // x: 4,
          y: 100
        },
      },
      scales: {
        xAxes: [{
          stacked: true,
          gridLines: { display: false },
          ticks: {
            maxRotation: 0,
            // padding: 10
          },
          type: 'category',
        }],
        yAxes: [{
          scaleLabel: {
            display: true,
            labelString: type
          }
        }]
      },
      title: {
        display: true,
        text: label
      },
      legend: {
        display: false,
      },
    }
  });
}

function openChart(label, type, data) {
  $('#chartModal').modal('show');
  setTimeout(() => buildChart(label, type, data), 200);
}


/* PASSWORDS */

// The one and only function called from the HTML code
function togglePassView() {

}
function generatePassword() {
  var charsetStr = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ[=+!#$%^&*-]";

  // Convert to array and remove duplicate characters
  var charset = [];
  for (var i = 0; i < charsetStr.length; i++) {
    var c = charsetStr.charCodeAt(i);
    var s = null;
    if (c < 0xD800 || c >= 0xE000)  // Regular UTF-16 character
      s = charsetStr.charAt(i);
    else if (0xD800 <= c && c < 0xDC00) {  // High surrogate
      if (i + 1 < charsetStr.length) {
        var d = charsetStr.charCodeAt(i + 1);
        if (0xDC00 <= d && d < 0xE000) {
          // Valid character in supplementary plane
          s = charsetStr.substr(i, 2);
          i++;
        }
        // Else discard unpaired surrogate
      }
    } else if (0xDC00 <= d && d < 0xE000)  // Low surrogate
      i++;  // Discard unpaired surrogate
    else
      throw "Assertion error";
    if (s != null && charset.indexOf(s) == -1)
      charset.push(s);
  }

  var strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{12,})');
  do {
    var password = "";
    if (charset.length != 0) {

      var length = 12;
      if (length < 0)
        alert("Negative password length");
      else if (length > 10000)
        alert("Password length too large");
      else {
        for (var i = 0; i < length; i++)
          password += charset[randomInt(charset.length)];

        var entropy = Math.log(charset.length) * length / Math.log(2);
        var entropystr;
        if (entropy < 70)
          entropystr = entropy.toFixed(2);
        else if (entropy < 200)
          entropystr = entropy.toFixed(1);
        else
          entropystr = entropy.toFixed(0);
        //statistics = "Length = " + length + " chars, \u00A0\u00A0Charset size = " + charset.length + " symbols, \u00A0\u00A0Entropy = " + entropystr + " bits";
      }
    }
  } while (!strongRegex.test(password));
  return password;
}


// Returns a random integer in the range [0, n) using a variety of methods
function randomInt(n) {
  var x = randomIntMathRandom(n);
  x = (x + randomIntBrowserCrypto(n)) % n;
  return x;
}


// Not secure or high quality, but always available
function randomIntMathRandom(n) {
  var x = Math.floor(Math.random() * n);
  if (x < 0 || x >= n)
    throw "Arithmetic exception";
  return x;
}


var cryptoObject = null;

// Uses a secure, unpredictable random number generator if available; otherwise returns 0
function randomIntBrowserCrypto(n) {
  if (cryptoObject == null)
    return 0;
  // Generate an unbiased sample
  var x = new Uint32Array(1);
  do cryptoObject.getRandomValues(x);
  while (x[0] - x[0] % n > 4294967296 - n);
  return x[0] % n;
}

function showp1() {
  var p = document.getElementById('password_1');
  p.setAttribute('type', 'text');
}

function hidep1() {
  var p = document.getElementById('password_1');
  p.setAttribute('type', 'password');
}

var p1Shown = 0;

function showPassp1() {

  if (p1Shown == 0) {
    p1Shown = 1;
    showp1();
  } else {
    p1Shown = 0;
    hidep1();
  }
}

function showp2() {
  var p = document.getElementById('password_2');
  p.setAttribute('type', 'text');
}

function hidep2() {
  var p = document.getElementById('password_2');
  p.setAttribute('type', 'password');
}

var p2Shown = 0;

function showPassp2() {

  if (p2Shown == 0) {
    p2Shown = 1;
    showp2();
  } else {
    p2Shown = 0;
    hidep2();
  }
}

/* Used for export buttons */
function disableButton(obj, time) {
  obj.disabled = true;
  setTimeout(function () {
    obj.disabled = false;
  }, time);
}

/*LE TICKET STUFF FIXME:*/

$('.ticket-tr-open').on('click', function () {
  var ticket = $(this).children('td').children(".ticket-btn-open").data('ticket');
  $('.ticket-messages').hide();
  if ($(this).children('td').children(".ticket-btn-open").hasClass('active')) {
    $(this).children('td').children(".ticket-btn-open").removeClass('active');
    $(this).children('td').children(".ticket-btn-open").removeClass('li-bg-light-blue');
    $('.ticket-messages[data-ticket="' + ticket + '"]').hide();
  } else {
    $('.ticket-btn-open').removeClass('active');
    $('.ticket-btn-open').removeClass('li-bg-light-blue');
    $(this).children('td').children(".ticket-btn-open").addClass('active');
    $(this).children('td').children(".ticket-btn-open").addClass('li-bg-light-blue');
    $('.ticket-messages[data-ticket="' + ticket + '"]').show();
    var message_badge_num = Number($('#message-badge').text());
    var ticket_conv_num = Number($('#conversation-badge[data-ticket="' + ticket + '"]').text());
    var result_unred = message_badge_num - ticket_conv_num;
    if (result_unred == 0) {
      result_unred = '';
    }
    $('#message-badge').text(result_unred)
    $('#conversation-badge[data-ticket="' + ticket + '"]').text("");
    markTicketsAsRead(ticket);
  }
  var count = $('.ticket-btn-open.active').length;
  if (count == 0) {
    $('.ticket-messages-default').show();
  } else {
    $('.ticket-messages-default').hide();
  }
});

function markTicketsAsRead(ticket_id) {
  jQuery.ajax({
    type: 'POST',
    async: true,
    dataType: 'json',
    url: 'api/markticketread/',
    data: { 'ticket_id': ticket_id },
    success: function (result) {
      var counter = result.data;
      if (counter > 0) {
        //conversation-badge-<?=$ticket->id
        var badger = $('#conversation-badge-' + ticket_id);
        var current_counter = parseInt(badger.text());
        var current_counter_profile = parseInt($('#message-badge').text());
        var updated_counter = current_counter_profile - current_counter;
        if (updated_counter > 0) {
          $('#message-badge').html(updated_counter);
        } else {
          $('#message-badge').html("");
        }
        badger.html("");
      }
    }
  });
}
